import { bindable, containerless, inject } from 'aurelia-framework';
import { RelativeTime }                    from 'aurelia-i18n';
import { AppContainer }                    from 'resources/services/app-container';
import { NotificationsRepository }         from 'modules/personal-area/notifications/services/repository';
import { UsersRepository }                 from 'modules/administration/users/services/repository';

@containerless()
@inject(AppContainer, RelativeTime, NotificationsRepository, UsersRepository)
export class NotificationsCard {

    @bindable headerTitle = 'dashboard.title.notifications';

    criteria = {
        status_id_exclude: 3,
    };

    notifications = [];

    /**
     * Constructor
     *
     * @param appContainer
     * @param relativeTime
     * @param repository
     * @param usersRepository
     */
    constructor(appContainer, relativeTime, repository, usersRepository) {
        this.appContainer    = appContainer;
        this.relativeTime    = relativeTime;
        this.repository      = repository;
        this.usersRepository = usersRepository;
    }

    /**
     * Handles bind event
     */
    bind() {
        this.fetchData();
    }

    /**
     * Fetches data from server
     *
     * @returns {*}
     */
    fetchData() {
        return this.usersRepository.unreadNotifications().then(({notifications}) => {
            this.notifications.splice(0, this.notifications.length, ...notifications);
        });
    }

    /**
     * Handles view action button
     *
     * @param row
     */
    viewNotificationHandler(row) {
        if (row.relatable && row.route) {
            let route = this.appContainer.router.generate(row.route, {id: row.relatable.id});

            // marks the task has read
            this.repository.read(row.id).then(() => this.appContainer.router.navigate(route));
        }
    }

    /**
     * Gets relative time from a given date
     *
     * @param date
     *
     * @returns {*|any}
     */
    getRelativeTime(date) {
        let time = new Date(date);

        return this.relativeTime.getRelativeTime(time);
    }

}
